import React, { useState, useEffect, createContext, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { GoogleGenerativeAI } from '@google/generative-ai';
import OpenAI from "openai";
import ReactMarkdown from 'react-markdown';
import { BrowserRouter as Router, Route, Routes, Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import BlogPost from './components/BlogPost';

const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GOOGLE_API_KEY);

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 1991 }, (_, i) => (currentYear - i).toString());

const languages = [
  { code: 'es', name: 'Español' },
  { code: 'fr', name: 'Français' },
  { code: 'en', name: 'English' },
  { code: 'it', name: 'Italiano' },
  { code: 'pt', name: 'Português' },
  { code: 'de', name: 'Deutsch' },
  { code: 'ar', name: 'العربية' }
];

const translations = {
  es: {
    title: "Diagnóstico de tu coche con IA",
    subtitle: "IAutoFIX",
    vehicleInfo: "Información del Vehículo",
    brand: "Marca",
    model: "Modelo",
    engine: "Motor",
    fuel: "Combustible",
    year: "Año",
    diagnosticMode: "Modo de Diagnóstico",
    textDiagnostic: "Diagnóstico por Texto",
    customDiagnostic: "Diagnóstico Personalizado",
    imageDiagnostic: "Diagnóstico por Imagen",
    routIssue: "Describe el problema del vehículo",
    describeIssue: "Describe el problema del vehículo",
    symptoms: "Describe los síntomas de tu vehículo: incluye luces, ruidos, olores, daños en la carrocería y otros detalles para facilitar el diagnóstico...",
    customSymptoms: "Describe cualquier problema o consulta detalladamente sobre tu vehículo...",
    obdCode: "Código OBD/OBD2",
    uploadImage: "Sube la imagen de la pieza a analizar",
    dragDrop: "Arrastra y suelta una imagen aquí, o haz clic para seleccionar una",
    imageLoaded: "Imagen cargada",
    dropImage: "Suelta la imagen aquí...",
    analyze: "Analizar",
    analyzing: "Analizando...",
    pleaseWait: "Analizando, por favor espere...",
    footer: "Todos los derechos reservados.",
    imageLimitReached: "Límite de análisis de imágenes alcanzado. Por favor, inténtalo de nuevo en 12 horas.",
    textLimitReached: "Límite de análisis de texto alcanzado. Por favor, inténtalo de nuevo en 12 horas.",
    news: "Noticias",
    appGuide: "Guía de la App",
    newsTitle: "Noticias sobre IA y Automóviles",
    newsContent: "Mantente actualizado con las últimas noticias sobre inteligencia artificial en el sector automotriz.",
    appGuideTitle: "Guía de la Aplicación IAutoFIX (OBD-OBD2)",
    appGuideContent: "Aprende a utilizar IAutoFIX para diagnosticar y reparar tu vehículo de manera eficiente."
  },
  fr: {
    title: "Diagnostic de voiture par l'IA",
    subtitle: "IAutoFIX",
    vehicleInfo: "Informations sur le Véhicule",
    brand: "Marque",
    model: "Modèle",
    engine: "Moteur",
    fuel: "Carburant",
    year: "Année",
    diagnosticMode: "Mode de Diagnostic",
    textDiagnostic: "Diagnostic par Texte",
    customDiagnostic: "Diagnostic Personnalisé",
    imageDiagnostic: "Diagnostic par Image",
    routIssue: "Décrivez le problème du véhicule",
    describeIssue: "Décrivez le problème du véhicule",
    symptoms: "Décrivez les symptômes de votre véhicule : incluez les lumières, les bruits, les odeurs, les dommages à la carrosserie et d'autres détails pour faciliter le diagnostic...",
    customSymptoms: "Décrivez en détail tout problème ou question concernant votre véhicule...",
    obdCode: "Code OBD/OBD2",
    uploadImage: "Téléchargez l'image de la pièce à analyser",
    dragDrop: "Faites glisser et déposez une image ici, ou cliquez pour en sélectionner une",
    imageLoaded: "Image chargée",
    dropImage: "Déposez l'image ici...",
    analyze: "Analyser",
    analyzing: "Analyse en cours...",
    pleaseWait: "Analyse en cours, veuillez patienter...",
    footer: "Tous droits réservés.",
    imageLimitReached: "Limite d'analyse d'images atteinte. Veuillez réessayer dans 12 heures.",
    textLimitReached: "Limite d'analyse de texte atteinte. Veuillez réessayer dans 12 heures.",
    news: "Actualités",
    appGuide: "Guide de l'Application",
    newsTitle: "Actualités sur l'IA et l'Automobile",
    newsContent: "Restez informé des dernières nouvelles sur l'intelligence artificielle dans le secteur automobile.",
    appGuideTitle: "Guide de l'Application IAutoFIX (OBD-OBD2)",
    appGuideContent: "Apprenez à utiliser IAutoFIX pour diagnostiquer et réparer votre véhicule efficacement."
  },
  en: {
    title: "AI Car Fault Diagnosis",
    subtitle: "IAutoFIX",
    vehicleInfo: "Vehicle Information",
    brand: "Make",
    model: "Model",
    engine: "Engine",
    fuel: "Fuel",
    year: "Year",
    diagnosticMode: "Diagnostic Mode",
    textDiagnostic: "Text Diagnostic",
    customDiagnostic: "Custom Diagnosis",
    imageDiagnostic: "Image Diagnostic",
    routIssue: "Describe the vehicle issue",
    describeIssue: "Describe the vehicle issue",
    symptoms: "Describe the symptoms of your vehicle: include lights, noises, odors, body damage, and any other details to assist with the diagnosis...",
    customSymptoms: "Describe any issues with your vehicle in detail,...",
    obdCode: "OBD/OBD2 Code",
    uploadImage: "Upload the image of the part to analyze",
    dragDrop: "Drag and drop an image here, or click to select one",
    imageLoaded: "Image loaded",
    dropImage: "Drop the image here...",
    analyze: "Analyze",
    analyzing: "Analyzing...",
    pleaseWait: "Analyzing, please wait...",
    footer: "All rights reserved.",
    imageLimitReached: "Image analysis limit reached. Please try again in 12 hours.",
    textLimitReached: "Text analysis limit reached. Please try again in 12 hours.",
    news: "News",
    appGuide: "App Guide",
    newsTitle: "AI and Automotive News",
    newsContent: "Stay updated with the latest news on artificial intelligence in the automotive sector.",
    appGuideTitle: "IAutoFIX Application Guide (OBD-OBD2)",
    appGuideContent: "Learn how to use IAutoFIX to diagnose and repair your vehicle efficiently."
  },
  it: {
    title: "Diagnosi della tua auto con IA",
    subtitle: "IAutoFIX",
    vehicleInfo: "Informazioni sul Veicolo",
    brand: "Marca",
    model: "Modello",
    engine: "Motore",
    fuel: "Carburante",
    year: "Anno",
    diagnosticMode: "Modalità Diagnostica",
    textDiagnostic: "Diagnostica Testuale",
    customDiagnostic: "Diagnosi Personalizzata",
    imageDiagnostic: "Diagnostica per Immagini",
    routIssue: "Descrivi il problema del veicolo",
    describeIssue: "Descrivi il problema del veicolo",
    symptoms: "Descrivi i sintomi del tuo veicolo: includi luci, rumori, odori, danni alla carrozzeria e altri dettagli per facilitare la diagnosi...",
    customSymptoms: "Descrivi in dettaglio qualsiasi problema o domanda sul tuo veicolo...",
    obdCode: "Codice OBD/OBD2",
    uploadImage: "Carica l'immagine del pezzo da analizzare",
    dragDrop: "Trascina e rilascia un'immagine qui, o clicca per selezionarne una",
    imageLoaded: "Immagine caricata",
    dropImage: "Rilascia l'immagine qui...",
    analyze: "Analizza",
    analyzing: "Analisi in corso...",
    pleaseWait: "Analisi in corso, attendere prego...",
    footer: "Tutti i diritti riservati.",
    imageLimitReached: "Limite di analisi delle immagini raggiunto. Si prega di riprovare tra 12 ore.",
    textLimitReached: "Limite di analisi del testo raggiunto. Si prega di riprovare tra 12 ore.",
    news: "Notizie",
    appGuide: "Guida dell'App",
    newsTitle: "Notizie su IA e Automotive",
    newsContent: "Rimani aggiornato con le ultime notizie sull'intelligenza artificiale nel settore automobilistico.",
    appGuideTitle: "Guida all'Applicazione IAutoFIX (OBD-OBD2)",
    appGuideContent: "Impara a utilizzare IAutoFIX per diagnosticare e riparare il tuo veicolo in modo efficiente."
  },
  pt: {
    title: "Diagnóstico do seu carro com IA",
    subtitle: "IAutoFIX",
    vehicleInfo: "Informações do Veículo",
    brand: "Marca",
    model: "Modelo",
    engine: "Motor",
    fuel: "Combustível",
    year: "Ano",
    diagnosticMode: "Modo de Diagnóstico",
    textDiagnostic: "Diagnóstico por Texto",
    customDiagnostic: "Diagnóstico Personalizado",
    imageDiagnostic: "Diagnóstico por Imagem",
    routIssue: "Descreva o problema do veículo",
    describeIssue: "Descreva o problema do veículo",
    customSymptoms: "Descreva em detalhe qualquer problema ou dúvida sobre o seu veículo...",
    symptoms: "Descreva os sintomas do seu veículo: inclua luzes, ruídos, odores, danos na carroceria e outros detalhes para facilitar o diagnóstico...",
    obdCode: "Código OBD/OBD2",
    uploadImage: "Carregue a imagem da peça a analisar",
    dragDrop: "Arraste e solte uma imagem aqui, ou clique para selecionar uma",
    imageLoaded: "Imagem carregada",
    dropImage: "Solte a imagem aqui...",
    analyze: "Analisar",
    analyzing: "Analisando...",
    pleaseWait: "Analisando, por favor aguarde...",
    footer: "Todos os direitos reservados.",
    imageLimitReached: "Limite de análise de imagens atingido. Por favor, tente novamente em 12 horas.",
    textLimitReached: "Limite de análise de texto atingido. Por favor, tente novamente em 12 horas.",
    news: "Notícias",
    appGuide: "Guia do Aplicativo",
    newsTitle: "Notícias sobre IA e Automotivo",
    newsContent: "Mantenha-se atualizado com as últimas notícias sobre inteligência artificial no setor automotivo.",
    appGuideTitle: "Guia da Aplicação IAutoFIX (OBD-OBD2)",
    appGuideContent: "Aprenda a usar o IAutoFIX para diagnosticar e reparar seu veículo de forma eficiente."
  },
  de: {
    title: "KI-gestützte Diagnose Ihres Autos",
    subtitle: "IAutoFIX",
    vehicleInfo: "Fahrzeuginformationen",
    brand: "Marke",
    model: "Modell",
    engine: "Motor",
    fuel: "Kraftstoff",
    year: "Jahr",
    diagnosticMode: "Diagnosemodus",
    textDiagnostic: "Textdiagnose",
    customDiagnostic: "Benutzerdefinierte Diagnose",
    imageDiagnostic: "Bilddiagnose",
    routIssue: "Beschreiben Sie das Fahrzeugproblem",
    describeIssue: "Beschreiben Sie das Problem des Fahrzeugs",
    symptoms: "Beschreiben Sie die Symptome Ihres Fahrzeugs: einschließlich Lichter, Geräusche, Gerüche, Schäden an der Karosserie und andere Details zur Erleichterung der Diagnose...",
    customSymptoms: "Beschreiben Sie detailliert jedes Problem oder jede Frage zu Ihrem Fahrzeug...",
    obdCode: "OBD/OBD2-Code",
    uploadImage: "Laden Sie das Bild des zu analysierenden Teils hoch",
    dragDrop: "Ziehen Sie ein Bild hierher oder klicken Sie, um eines auszuwählen",
    
    imageLoaded: "Bild geladen",
    dropImage: "Bild hier ablegen...",
    analyze: "Analysieren",
    analyzing: "Analyse läuft...",
    pleaseWait: "Analyse läuft, bitte warten...",
    footer: "Alle Rechte vorbehalten.",
    imageLimitReached: "Bildanalyse-Limit erreicht. Bitte versuchen Sie es in 12 Stunden erneut.",
    textLimitReached: "Textanalyse-Limit erreicht. Bitte versuchen Sie es in 12 Stunden erneut.",
    news: "Neuigkeiten",
    appGuide: "App-Anleitung",
    newsTitle: "Nachrichten über KI und Automotive",
    newsContent: "Bleiben Sie auf dem Laufenden mit den neuesten Nachrichten über künstliche Intelligenz in der Automobilbranche.",
    appGuideTitle: "Leitfaden zur IAutoFIX-App (OBD-OBD2)",
    appGuideContent: "Lernen Sie, wie Sie IAutoFIX effizient zur Diagnose und Reparatur Ihres Fahrzeugs einsetzen können."
  },
  ar: {
    title: "تشخيص سيارتك باستخدام الذكاء الاصطناعي",
    subtitle: "آي أوتو فيكس",
    vehicleInfo: "معلومات السيارة",
    brand: "الماركة",
    model: "الموديل",
    engine: "المحرك",
    fuel: "الوقود",
    year: "السنة",
    diagnosticMode: "وضع التشخيص",
    textDiagnostic: "التشخيص النصي",
    customDiagnostic: "تشخيص مخصص",
    imageDiagnostic: "التشخيص بالصورة",
    routIssue: "صف مشكلة السيارة",
    describeIssue: "وصف مشكلة المركبة",
    symptoms: "وصف أعراض سيارتك: تشمل الأضواء، الأصوات، الروائح، الأضرار في الهيكل وغيرها من التفاصيل لتسهيل التشخيص...",
    customSymptoms: "وصف أي مشكلة أو استفسار عن مركبتك بالتفصيل...",
    obdCode: "رمز OBD/OBD2",
    uploadImage: "قم بتحميل صورة القطعة المراد تحليلها",
    dragDrop: "اسحب وأفلت صورة هنا، أو انقر لتحديد واحدة",
    imageLoaded: "تم تحميل الصورة",
    dropImage: "أفلت الصورة هنا...",
    analyze: "تحليل",
    analyzing: "جاري التحليل...",
    pleaseWait: "جاري التحليل، يرجى الانتظار...",
    footer: "جميع الحقوق محفوظة.",
    imageLimitReached: "تم الوصول إلى حد تحليل الصور. يرجى المحاولة مرة أخرى بعد 12 ساعة.",
    textLimitReached: "تم الوصول إلى حد تحليل النص. يرجى المحاولة مرة أخرى بعد 12 ساعة.",
    news: "الأخبار",
    appGuide: "دليل التطبيق",
    newsTitle: "اخبار عن الذكاء الاصطناعي والسيارات",
    newsContent: "ابق على اطلاع بآخر الأخبار حول الذكاء الاصطناعي في قطاع السيارات.",
    appGuideTitle: "دليل تطبيق IAutoFIX (OBD-OBD2)",
    appGuideContent: "تعلم كيفية استخدام IAutoFIX لتشخيص وإصلاح سيارتك بكفاءة."
  }
};

const metaDescriptions = {
  en: "Discover how AI can diagnose car faults effectively and efficiently. Use IAutoFIX for quick and accurate automotive diagnostics.",
  es: "Descubre cómo la IA puede diagnosticar fallos de coches de manera efectiva y eficiente. Usa IAutoFIX para diagnósticos automotrices rápidos y precisos.",
  fr: "Découvrez comment l'IA peut diagnostiquer efficacement les pannes de voiture. Utilisez IAutoFIX pour des diagnostics automobiles rapides et précis.",
  pt: "Descubra como a IA pode diagnosticar falhas de carros de forma eficaz. Use o IAutoFIX para diagnósticos automotivos rápidos e precisos.",
  it: "Scopri come l'IA può diagnosticare guasti auto in modo efficace. Usa IAutoFIX per diagnosi automobilistiche rapide e accurate.",
  de: "Entdecken Sie, wie KI Auto-Fehler effektiv diagnostizieren kann. Nutzen Sie IAutoFIX für schnelle und genaue Fahrzeugdiagnosen.",
  ar: "اكتشف كيف يمكن للذكاء الاصطناعي تشخيص أعطال السيارات بكفاءة. استخدم IAutoFIX للحصول على تشخيصات سريعة ودقيقة للسيارات."
};

const pageTitles = {
  en: "IAutoFIX - AI Car Fault Diagnosis",
  es: "IAutoFIX - Diagnóstico de Fallos de Coches con IA",
  fr: "IAutoFIX - Diagnostic de Pannes Automobiles par IA",
  pt: "IAutoFIX - Diagnóstico de Falhas em Carros com IA",
  it: "IAutoFIX - Diagnosi Guasti Auto con IA",
  de: "IAutoFIX - KI-gestützte Fehlerdiagnose für Autos",
  ar: "IAutoFIX - تشخيص أعطال السيارات بالذكاء الاصطناعي"
};

const LanguageContext = createContext();

function getLocalizedPath(path, lang) {
  const pathMap = {
    en: {
      '/ai-car-fault-diagnosis': '/ai-car-fault-diagnosis',
      '/ai-and-automotive': '/ai-and-automotive',
      '/car-repair-obd-obd2': '/car-repair-obd-obd2',
    },
    es: {
      '/ai-car-fault-diagnosis': '/diagnostico-de-tu-coche-con-ia',
      '/ai-and-automotive': '/ia-y-automoviles',
      '/car-repair-obd-obd2': '/reparacion-de-coches-obd-obd2',
    },
    fr: {
      '/ai-car-fault-diagnosis': '/diagnostic-de-voiture-par-ia',
      '/ai-and-automotive': '/ia-et-automobile',
      '/car-repair-obd-obd2': '/reparation-de-voitures-obd-obd2',
    },
    it: {
      '/ai-car-fault-diagnosis': '/diagnosi-della-tua-auto-con-ia',
      '/ai-and-automotive': '/ia-e-automotive',
      '/car-repair-obd-obd2': '/riparazione-auto-obd-obd2',
    },
    pt: {
      '/ai-car-fault-diagnosis': '/diagnostico-do-seu-carro-com-ia',
      '/ai-and-automotive': '/ia-e-automotivo',
      '/car-repair-obd-obd2': '/reparacao-de-carros-obd-obd2',
    },
    de: {
      '/ai-car-fault-diagnosis': '/ki-gestuetzte-diagnose-ihres-autos',
      '/ai-and-automotive': '/ki-und-automotive',
      '/car-repair-obd-obd2': '/auto-reparatur-obd-obd2',
    },
    ar: {
      '/ai-car-fault-diagnosis': '/تشخيص-سيارتك-باستخدام-الذكاء-الاصطناعي',
      '/ai-and-automotive': '/الذكاء-الاصطناعي-و-السيارات',
      '/car-repair-obd-obd2': '/اصلاح-سيارتك-obd-obd2',
    },
  };

  return pathMap[lang]?.[path] || path;
}

function Navigation({ darkMode, setDarkMode }) {
  const { language, setLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    const newPath = getLocalizedPath(location.pathname, newLanguage);
    navigate(newPath);
  };

  return (
    <nav className={`navigation ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="nav-container">
        <div className="nav-links">
          <Link to={getLocalizedPath('/ai-car-fault-diagnosis', language)} className="nav-link">IAutoFIX</Link>
          <Link to={getLocalizedPath('/ai-and-automotive', language)} className="nav-link">{translations[language].news}</Link>
          <Link to={getLocalizedPath('/car-repair-obd-obd2', language)} className="nav-link">{translations[language].appGuide}</Link>
        </div>
        <div className="nav-controls">
          <button onClick={() => setDarkMode(!darkMode)} className="mode-toggle">
            {darkMode ? '☀️' : '🌙'}
          </button>
          <select 
            value={language} 
            onChange={handleLanguageChange}
            className="language-selector modern-input"
          >
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </nav>
  );
}

function NewsPage({ darkMode }) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <div className={`news-page ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <Helmet>
        <title>{`${pageTitles[language]} - ${t.news}`}</title>
        <meta name="description" content={`${metaDescriptions[language]} ${t.newsContent}`} />
      </Helmet>
      <h1 className='h1-news'>{t.newsTitle}</h1>
      <Link to={getLocalizedPath('/ai-car-fault-diagnosis', language)} className="cta-button">{t.subtitle}</Link>
      <BlogPost />
    </div>
  );
}

function AboutPage({ darkMode }) {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <div className={`about-page ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <Helmet>
        <title>{`${pageTitles[language]} - ${t.appGuide}`}</title>
        <meta name="description" content={`${metaDescriptions[language]} ${t.appGuideContent}`} />
      </Helmet>
      <header>
        <h1>{t.appGuideTitle}</h1>
      </header>
      <main>
        <section className="intro">
          <h3>{t.subtitle}</h3>
          <p>{t.appGuideContent}</p>
        </section>
        <section>
          <h2>{t.vehicleInfo}</h2>
          <p>{t.describeIssue}</p>
          <ul>
            <li><strong>{t.brand}:</strong> {t.brand}</li>
            <li><strong>{t.model}:</strong> {t.model}</li>
            <li><strong>{t.year}:</strong> {t.year}</li>
          </ul>
        </section>
        <section>
          <h2>{t.diagnosticMode}</h2>
          <p>{t.customSymptoms}</p>
          <ul>
            <li><strong>{t.textDiagnostic}:</strong> {t.symptoms}</li>
            <li><strong>{t.obdCode}:</strong> {t.obdCode}</li>
            <li><strong>{t.imageDiagnostic}:</strong> {t.uploadImage}</li>
          </ul>
        </section>
        <section>
          <h2>{t.analyze}</h2>
          <p>{t.pleaseWait}</p>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 IAutoFIX. {t.footer}</p>
      </footer>
    </div>
  );
}

function DiagnosticContent({ darkMode, setDarkMode }) {
  const { language } = useContext(LanguageContext);
  const { action } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [image, setImage] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState({
    marca: '',
    modelo: '',
    motor: '',
    combustible: '',
    ano: ''
  });
  const [diagnosticMode, setDiagnosticMode] = useState(action || 'text-diagnostic');
  const [textPrompt, setTextPrompt] = useState('');
  const [customPrompt, setCustomPrompt] = useState('');
  const [obdCode, setObdCode] = useState('');
  const [textApiUsage, setTextApiUsage] = useState(10);
  const [imageApiUsage, setImageApiUsage] = useState(3);
  const [uuid, setUuid] = useState('');

  useEffect(() => {
    document.body.classList.toggle('dark-mode', darkMode);
    document.body.classList.toggle('light-mode', !darkMode);
  }, [darkMode]);

  useEffect(() => {
    let storedUuid = localStorage.getItem('userUuid');
    if (!storedUuid) {
      storedUuid = crypto.randomUUID();
      localStorage.setItem('userUuid', storedUuid);
    }
    setUuid(storedUuid);

    const now = new Date().getTime();
    const lastResetTime = parseInt(localStorage.getItem(`lastResetTime_${storedUuid}`), 10) || 0;
    const twelveHoursInMs = 12 * 60 * 60 * 1000;

    if (now - lastResetTime >= twelveHoursInMs) {
      setTextApiUsage(10);
      localStorage.setItem(`textApiUsage_${storedUuid}`, '10');
      localStorage.setItem(`lastResetTime_${storedUuid}`, now.toString());
    } else {
      const storedTextApiUsage = localStorage.getItem(`textApiUsage_${storedUuid}`);
      if (storedTextApiUsage) {
        setTextApiUsage(parseInt(storedTextApiUsage, 10));
      }
    }

    const storedImageApiUsage = localStorage.getItem(`imageApiUsage_${storedUuid}`);
    if (storedImageApiUsage) {
      setImageApiUsage(parseInt(storedImageApiUsage, 10));
    }
  }, []);

  useEffect(() => {
    if (action && ['text-diagnostic', 'custom-diagnostic', 'image-diagnostic'].includes(action)) {
      setDiagnosticMode(action);
    }
  }, [action]);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setImage(URL.createObjectURL(file));
      setAnalysis(null);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {'image/*': []},
    disabled: !!image,
    maxFiles: 1
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicleInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const removeImage = () => {
    setImage(null);
    setAnalysis(null);
  };

  const updateApiUsage = (mode) => {
    if (mode === 'text' || mode === 'custom') {
      const newUsage = textApiUsage - 1;
      setTextApiUsage(newUsage);
      localStorage.setItem(`textApiUsage_${uuid}`, newUsage.toString());
    } else if (mode === 'image') {
      const newUsage = imageApiUsage - 1;
      setImageApiUsage(newUsage);
      localStorage.setItem(`imageApiUsage_${uuid}`, newUsage.toString());
    }
  };

  const analyzeIssue = async () => {
    if (diagnosticMode === 'image-diagnostic' && !image) return;
    if (diagnosticMode === 'text-diagnostic' && !textPrompt && !obdCode) return;
    if (diagnosticMode === 'custom-diagnostic' && !customPrompt) return;

    if (diagnosticMode === 'image-diagnostic' && imageApiUsage <= 0) {
      setAnalysis({ error: translations[language].imageLimitReached });
      return;
    }

    if ((diagnosticMode === 'text-diagnostic' || diagnosticMode === 'custom-diagnostic') && textApiUsage <= 0) {
      setAnalysis({ error: translations[language].textLimitReached });
      return;
    }

    setLoading(true);
    try {
      if (diagnosticMode === 'image-diagnostic') {
        const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro" });
        const prompt = `Analyze the image of a ${vehicleInfo.marca} ${vehicleInfo.modelo} (${vehicleInfo.ano}) with ${vehicleInfo.motor} engine and ${vehicleInfo.combustible}. Give the response with bold title of each step:: 1. Name and function. 2. Visible issues. 3. Likely causes. 4. Repair Costs, providing three price options and brands (cheapest, good value, premium) in the country where we speak ${language}. If not automotive-related, state that and ask for a clearer image. If related, analyze it. If no issues found, suggest using another option in this app (OBD code, written analysis or image analysis). Do not recommend external workshops; encourage using this app for further assistance. Optimize for fewer tokens and give the response in ${language}.`;

        const result = await model.generateContent([
          prompt,
          {
            inlineData: {
              mimeType: 'image/jpeg',
              data: await fileToBase64(image)
            }
          }
        ]);

        const analysisText = result.response.text();
        setAnalysis({ text: analysisText });
        updateApiUsage('image');
      } else {
        let prompt;
        if (diagnosticMode === 'text-diagnostic') {
          if (obdCode) {
            prompt = `${obdCode}" for ${vehicleInfo.marca} ${vehicleInfo.modelo} (${vehicleInfo.ano}), ${vehicleInfo.motor} engine, ${vehicleInfo.combustible} fuel. Give the response with bold title of each step: 1. Error Description: Explain the code. 2. Possible Causes: List triggers.  3. Severity: Rate urgency. 4. Repair Steps: Provide DIY steps. 5. Repair Costs: Estimate parts/labor. 6. Prevention: Suggest maintenance. Optimize for fewer tokens and give the response in ${language}.`;
          } else {
            prompt = `Analyze "${textPrompt}" for ${vehicleInfo.marca} ${vehicleInfo.modelo} (${vehicleInfo.ano}), ${vehicleInfo.motor} engine, ${vehicleInfo.combustible} fuel. Give the response with bold title of each step: 1. Issue Summary: Summarize problem. 2. Possible Causes: List potential reasons. 3. Diagnostic Steps: Suggest checks. 4. Repair Options: Provide DIY and professional solutions. 5. Estimated Costs: Give price range. 6. Prevention: Offer maintenance tips. Optimize for fewer tokens and give the response in ${language}.`;
          }
        } else if (diagnosticMode === 'custom-diagnostic') {
          prompt = `Analyze "${customPrompt}" for ${vehicleInfo.marca} ${vehicleInfo.modelo} (${vehicleInfo.ano}), ${vehicleInfo.motor} engine, ${vehicleInfo.combustible} fuel. Provide a detailed response addressing the specific query or issue. Include relevant technical information, potential solutions, and any necessary precautions. Optimize for fewer tokens and give the response in ${language}.`;
        }

        const completion = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: [{ role: "user", content: prompt }],
        });

        const analysisText = completion.choices[0].message.content;
        setAnalysis({ text: analysisText });
        updateApiUsage('text');
      }
    } catch (error) {
      console.error('Error during analysis:', error);
      setAnalysis({ error: 'An error occurred during analysis. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const fileToBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = reject;
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      analyzeIssue();
    }
  };

  const handleActionChange = (newAction) => {
    setDiagnosticMode(newAction);
    navigate(getLocalizedPath(`/${newAction}`, language));
  };

  const t = translations[language];

  return (
    <div className={`App ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <Helmet>
        <html lang={language} />
        <title>{pageTitles[language]}</title>
        <meta name="description" content={metaDescriptions[language]} />
        <link rel="canonical" href={`https://iautofix.com${getLocalizedPath(location.pathname, language)}`} />
        {languages.map((lang) => (
          <link 
            key={lang.code} 
            rel="alternate" 
            hreflang={lang.code} 
            href={`https://iautofix.com${getLocalizedPath(location.pathname, lang.code)}`} 
          />
        ))}
      </Helmet>
      <header>
        <h1>{t.title}</h1>
        <h2>{t.subtitle}</h2>
      </header>
      <main>
        <section className="vehicle-info">
          <h2>{t.vehicleInfo}</h2>
          <div className="input-group">
            <input
              type="text"
              name="marca"
              value={vehicleInfo.marca}
              onChange={handleInputChange}
              placeholder={t.brand}
              className="modern-input"
            />
            <input
              type="text"
              name="modelo"
              value={vehicleInfo.modelo}
              onChange={handleInputChange}
              placeholder={t.model}
              className="modern-input"
            />
            <input
              type="text"
              name="motor"
              value={vehicleInfo.motor}
              onChange={handleInputChange}
              placeholder={t.engine}
              className="modern-input"
            />
            <input
              type="text"
              name="combustible"
              value={vehicleInfo.combustible}
              onChange={handleInputChange}
              placeholder={t.fuel}
              className="modern-input"
            />
            <select 
              name="ano" 
              value={vehicleInfo.ano} 
              onChange={handleInputChange}
              className="modern-input"
            >
              <option value="">{t.year}</option>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
        </section>

        <section className="diagnostic-mode">
          <h2>{t.diagnosticMode}</h2>
          <div className="mode-selector">
            <button 
              className={diagnosticMode === 'text-diagnostic' ? 'active' : ''}
              onClick={() => handleActionChange('text-diagnostic')}
            >
              {t.textDiagnostic}
            </button>
            <button 
              className={diagnosticMode === 'image-diagnostic' ? 'active' : ''}
              onClick={() => handleActionChange('image-diagnostic')}
            >
              {t.imageDiagnostic}
            </button>
            <button 
              className={diagnosticMode === 'custom-diagnostic' ? 'active' : ''}
              onClick={() => handleActionChange('custom-diagnostic')}
            >
              {t.customDiagnostic}
            </button>
          </div>
        </section>

        {diagnosticMode === 'text-diagnostic' && (
          <section className="text-prompt-section">
            <h2>{t.routIssue}</h2>
            <textarea
              value={textPrompt}
              onChange={(e) => setTextPrompt(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={t.symptoms}
              rows="4"
              className="modern-textarea"
            ></textarea>
            <input
              type="text"
              value={obdCode}
              onChange={(e) => setObdCode(e.target.value)}
              placeholder={t.obdCode}
              className="modern-input obd-input"
            />
            <p className="usage-limit">Text analysis remaining: {textApiUsage}</p>
          </section>
        )}

        {diagnosticMode === 'custom-diagnostic' && (
          <section className="text-prompt-section">
            <h2>{t.describeIssue}</h2>
            <textarea
              value={customPrompt}
              onChange={(e) => setCustomPrompt(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={t.customSymptoms}
              rows="4"
              className="modern-textarea"
            ></textarea>
            <p className="usage-limit">Custom analysis remaining: {textApiUsage}</p>
          </section>
        )}

        {diagnosticMode === 'image-diagnostic' && (
          <section className="upload-section">
            <h2>{t.uploadImage}</h2>
            <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} ${image ? 'has-image' : ''}`}>
              <input {...getInputProps()} />
              {image ? (
                <p>{t.imageLoaded}</p>
              ) : isDragActive ? (
                <p>{t.dropImage}</p>
              ) : (
                <p>{t.dragDrop}</p>
              )}
            </div>
            {image && (
              <div className="image-preview">
                <button onClick={removeImage} className="remove-image">X</button>
                <img src={image} alt="Vehicle part" />
              </div>
            )}
            <p className="usage-limit">Image analysis remaining: {imageApiUsage}</p>
          </section>
        )}

        <button 
          onClick={analyzeIssue} 
          className="analyze-btn" 
          disabled={loading || 
            (diagnosticMode === 'image-diagnostic' && !image) || 
            (diagnosticMode === 'text-diagnostic' && !textPrompt && !obdCode) ||
            (diagnosticMode === 'custom-diagnostic' && !customPrompt)}
        >
          {loading ? t.analyzing : t.analyze}
        </button>

        {loading && (
          <div className="loading">
            <div className="loading-animation"></div>
            <p>{t.pleaseWait}</p>
          </div>
        )}

        {analysis && analysis.text && (
          <section className="analysis-results">
            <ReactMarkdown>{analysis.text}</ReactMarkdown>
          </section>
        )}

        {analysis && analysis.error && (
          <section className="error-message">
            <p>{analysis.error}</p>
          </section>
        )}
      </main>
      <footer>
        <p>&copy; 2024 IAutoFIX. {t.footer}</p>
      </footer>
    </div>
  );
}

export default function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [language, setLanguage] = useState(() => {
    const browserLang = navigator.language.split('-')[0];
    return languages.some(lang => lang.code === browserLang) ? browserLang : 'en';
  });

  return (
    <HelmetProvider>
      <Router>
        <LanguageContext.Provider value={{ language, setLanguage }}>
          <div className={`app-container ${darkMode ? 'dark-mode' : 'light-mode'}`}>
            <Navigation darkMode={darkMode} setDarkMode={setDarkMode} />
            <Routes>
              <Route 
                path="/" 
                element={<DiagnosticContent darkMode={darkMode} setDarkMode={setDarkMode} />} 
              />
              {languages.map((lang) => (
                <React.Fragment key={lang.code}>
                  <Route 
                    path={getLocalizedPath('/ai-car-fault-diagnosis', lang.code)}
                    element={<DiagnosticContent darkMode={darkMode} setDarkMode={setDarkMode} />}
                  />
                  <Route 
                    path={getLocalizedPath('/ai-and-automotive', lang.code)}
                    element={<NewsPage darkMode={darkMode} />}
                  />
                  <Route 
                    path={getLocalizedPath('/car-repair-obd-obd2', lang.code)}
                    element={<AboutPage darkMode={darkMode} />}
                  />
                  <Route 
                    path={`${getLocalizedPath('/', lang.code)}/:action`}
                    element={<DiagnosticContent darkMode={darkMode} setDarkMode={setDarkMode} />}
                  />
                </React.Fragment>
              ))}
            </Routes>
          </div>
        </LanguageContext.Provider>
      </Router>
    </HelmetProvider>
  );
}